import React, { useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";


import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { useFormik } from 'formik';
import { addcrdSchema } from '../../schemas';

import { FaCreditCard } from "react-icons/fa";

import { getUser, postAddCredit, postPaymentWithCitiPay } from '../../apiservices/ApiServices';
import Loader from '../../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const initialValues = {
    credits: '',
}
const AddCredit = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [isPayLoder, setPayLoder] = useState(false);
    const [user, setUser] = useState([]);
    const navigate = useNavigate();

    useEffect(() => 
    {
        if(!localStorage.getItem('userToken'))
        {
            navigate("/");
        }
        else 
        {
            setLoading(true);
            getUser().then((res) => 
            {
                try {
                    setUser(res.data);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

           
                axios.interceptors.response.use(
                  (response) => response,
                  (error) => {
                    if (error.response && error.response.status === 401) {
                      localStorage.removeItem('authToken');
                      localStorage.removeItem('userToken');
                      navigate('/'); // Redirect to login screen
                    }
                    return Promise.reject(error);
                  }
                );
        }
    }, [navigate])

    const handlePayment = async (values, action) => {
        const isVarify = localStorage.getItem('isVarify');
        setPayLoder(true);
        if (isVarify === "true")
        {        
            const amount = parseInt(values.credits);
            let postdata =
            {
                "MobileNumber":user.mobilenumber,
                "Amount":amount,
                "Source":"ORZI.App",
                "TransactionType": "CREDIT",
                "TransactionNumber":"New"
            }
            
            postAddCredit(postdata).then((res) => {
                try {
                    if(res.data.succeeded=== true)
                    {
                        console.log(res.data.message);
                        postPaymentWithCitiPay(res.data.message).then((res) => {
                            try {
                                if(res.data.succeeded=== true)
                                {
                                    localStorage.setItem('userIdentifier', res.data.identifier);
                                    var link = document.createElement("a");
                                    link.href = res.data.message;
                                    link.click();
                                    link.remove();
                                    setPayLoder(false)
                                    action.resetForm();
                                }
                                else
                                {
                                    toast.error(res.data.message);
                                    setPayLoder(false)
                                }
                                
                            } catch (error) {
                                setPayLoder(false);
                                console.error(error);
                            } finally {
            
                            }
                        });
                    } 
                    else
                    {
                        toast.error(res.data.message);
                        setPayLoder(false);
                    }               
                } catch (error) {
                    setPayLoder(false);
                    console.error(error);
                } finally {

                }
            });
        }else {
            setPayLoder(false);
            toast.error('RECAPTCHA verification failed');
            setTimeout(() => window.location.reload(true), 3000);
        }
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addcrdSchema,
        onSubmit: (vlaues, action) => {
            handlePayment(vlaues);
        }
    })
    return (
        <>
            <Header UserData={user}/>
            <section id='pagecontent'>
                <ToastContainer />
                <div className='pagecontent-box'>
                    <Sibebar UserData={user}/>
                    <div className='contents'>
                        <div className='pagetitle'>
                            <h1>Add Credit</h1>
                        </div>
                        <div className='cards tables mt-2'>
                            <div className='cards-hedar'>
                                <h4>Add Credit</h4>
                            </div>
                            {isLoading ? ( 
                                <Loader />
                            ) : 
                                <div className='cards-body'>
                                    <div className='cardinfo forms'>
                                        <div className='usersinfos'>
                                            <p><b>User Name :</b> {user.emailId}</p>
                                            <p><b>User Mobile :</b> {user.mobilenumber} </p>
                                            <p><b>ORZI Credit :</b> {user.availableCredit}</p>
                                        </div>
                                        <p className='notescity'>*Important - on the payment page "City" is your Parish</p>
                                        <form id='addparticipant' onSubmit={handleSubmit} autoComplete="off">
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="credits">Credit to be added (Between £3-£50) e.g. 3.00</label>
                                                    <input 
                                                        id="credits"
                                                        name="credits"
                                                        className="input v-input" 
                                                        autoComplete='off'
                                                        value={values.credits}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.credits && touched.credits ? (
                                                        <p className='errormsg'>{errors.credits}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="credits">
                                                        <FaCreditCard />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='buttonbox mt-4'>
                                                <button type='submit' className='btn btn-green'>Add Credit</button>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {isPayLoder ? <Loader /> : null}
            </section>
        </>
      )
}

export default AddCredit