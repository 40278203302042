import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';
import Cards from '../../components/Cards/Cards';
import Loader from '../../components/Loader/Loader';
import { Col, Row, Button } from 'react-bootstrap';
import { FaUsers, FaCreditCard, FaCheck } from "react-icons/fa";
import { getDashboard, getUser } from '../../apiservices/ApiServices';
import { format } from 'date-fns';
import ErroeImg from '../../assets/images/error.png'
import axios from 'axios';

const Dashboard = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [shortCodeList, setShortCodeList] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MMMM yy');
  };

  useEffect(() => {
    setLoading(true);
    if(!localStorage.getItem('userToken'))
    {
      navigate("/");
    }
    else {
      getDashboard().then((res) => {
        try {
          setData(res.data);
          setShortCodeList(res.data.shortcodeList);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      });

      getUser().then((res) => {
        try {
          setUser(res.data);
          setLoading(false);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      });

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('userToken');
            navigate('/'); // Redirect to login screen
          }
          return Promise.reject(error);
        }
      );
    }
  }, [navigate])

  const customStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
      },
    },
    headCells: {
      style: {

        padding: '12px 15px',
        background: '#eaecf4',
        fontFamily: 'Poppins-Bold',
        fontSize: '16px',
        color: '#000',
        borderColor: '#e3e6f0',
      },
    },
    cells: {
      style: {
        padding: '12px 15px',
        background: '#fff',
        fontFamily: 'Poppins',
        fontSize: '15px',
        color: '#606060',
        borderColor: '#e3e6f0',
      },
    },
  };

  const columns = [
    {
      name: ' ',
      selector: row => <Link className='cmpingbox' to={'/activity/add-participant/' + row.shortcodeName}>
        <img src={row.fileName === '' ? ErroeImg : `${localStorage.getItem('apiUrl')}/Uploads/${row.fileName}`}
          className="cmpimg"
          alt="Campaign Image"
          title="Campaign Image"
          onError={(e) => {
            e.target.onerror = null; // prevents infinite loop in case the error image also fails to load
            e.target.src = ErroeImg; // replace with your error image
          }} /></Link>,
      sortable: true,
    },
    {
      name: 'Shortcode',
      selector: row => row.shortcodeName,
      sortable: true,

    },
    {
      name: 'Campaign Name',
      selector: row => row.campaignName,
      sortable: true,

    },
    {
      name: 'Entry Fee',
      selector: row => `${row.entryFee} Credits`,
      sortable: true,

    },
    {
      name: ' ',
      selector: row => <Link className='btn btn-green' to={'/activity/add-participant/' + row.shortcodeName}>Participate</Link>,
    },
  ];

  const totalPages = Math.ceil(shortCodeList.length / itemsPerPage);

  // Get the items for the current page
  const currentItems = shortCodeList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header UserData={user} />
      <section id='pagecontent'>
        <div className='pagecontent-box'>
          <Sibebar UserData={user} />
          <div className='contents'>
            <div className='pagetitle'>
              <h1>Dashboard</h1>
            </div>
            {isLoading ? (
              <Loader />
            ) : <>
              <div className='cardslist-count'>
                <Row>
                  <Col md={4}>
                    <Cards Title="Participation Count" Count={data.participant} Icon={<FaUsers />} />
                  </Col>
                  <Col md={4}>
                    <Cards Title="Available Credits" Count={data.availableCredit} Icon={<FaCreditCard />} />
                  </Col>
                  <Col md={4}>
                    <Cards Title="Transaction Count" Count={data.transaction} Icon={<FaCheck />} />
                  </Col>
                </Row>
              </div>
              <div className='cards tables'>
                <div className='cards-hedar'>
                  <h4>Active Shortcode / Campaign List</h4>
                </div>
                <div className='cards-body'>
                  <div className='resdnone-tab' id='deshboard'>
                    <DataTable
                      columns={columns}
                      data={shortCodeList}
                      customStyles={customStyles}
                      pagination
                      {...props}
                    />
                  </div>
                  <div className='tablescards'>
                    {currentItems.map((item) => {
                      return (
                        <div className='cardstbs' key={item.shortcodeId}>
                          <div className='cmpimg'>
                            <Link className='cmpingbox' to={'/activity/add-participant/' + item.shortcodeName}>
                              <img src={item.fileName === '' ? ErroeImg : `${localStorage.getItem('apiUrl')}/Uploads/${item.fileName}`}
                                className="cmpimg"
                                alt="Campaign Image"
                                title="Campaign Image"
                                onError={(e) => {
                                  e.target.onerror = null; // prevents infinite loop in case the error image also fails to load
                                  e.target.src = ErroeImg; // replace with your error image
                                }}
                              />
                            </Link>
                          </div>
                          <div className='cmpdata'>
                            <p><b>Shortcode :</b> {item.shortcodeName}</p>
                            <p><b>Campaign Name :</b> {`${item.campaignName} `}</p>
                            <p><b>Entry Fee :</b> {`${item.entryFee} Credits`}</p>
                          </div>
                          <div className='cmpbtns'>
                            <Link className='btn btn-green' to={'/activity/add-participant/' + item.shortcodeName}>Participate</Link>
                          </div>
                        </div>
                      );
                    })}
                    {shortCodeList.length > 5 ? (
                      <div className="pagination-controls">
                        <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</Button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <Button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>}
          </div>
        </div>
      </section>
    </>
  )
}
export default Dashboard